import PropTypes from 'prop-types';

import logo from 'assets/images/logo.png';

const LogoMain = () => {
  return <img src={logo} alt="Co-Operate" width="40" />;
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;

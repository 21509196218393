import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  objective: null
};

const objective = createSlice({
  name: 'objective',
  initialState,
  reducers: {
    setObjectives(state, action) {
      state.objectives = action.payload;
    },
    setObjective(state, action) {
      state.objective = action.payload;
    },
    setObjectiveError(state, action) {
      state.error = action.payload;
    }
  }
});

export default objective.reducer;

export const { setObjectives, setObjective, setObjectiveError } = objective.actions;

export const createObjective = (objectiveDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/objective`, objectiveDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setObjectives(data.objectives));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setObjectiveError(err.response.data.message));
      }
    });
};

export const getObjectivesByCompany = (companyId) => async (dispatch) => {
  axios
    .get(`${ROOT}/objective/company/${companyId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setObjectives(data.objectives));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setObjectiveError(err.response.data.message));
      }
    });
};

export const updateObjective = (objectiveDetails, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/objective`, objectiveDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setObjective(data.objective));

      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setObjectiveError(err.response.data.message));
      }
    });
};

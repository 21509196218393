import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  keyResult: null
};

const keyResult = createSlice({
  name: 'keyResult',
  initialState,
  reducers: {
    setKeyResults(state, action) {
      state.keyResults = action.payload;
    },
    setKeyResult(state, action) {
      state.keyResult = action.payload;
    },
    setKeyResultError(state, action) {
      state.error = action.payload;
    }
  }
});

export default keyResult.reducer;

export const { setKeyResults, setKeyResult, setKeyResultError } = keyResult.actions;

export const createKeyResult = (keyResultDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/key-result`, keyResultDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setKeyResults(data.keyResults));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setKeyResultError(err.response.data.message));
      }
    });
};

export const getKeyResultsByCompany = (companyId) => async (dispatch) => {
  axios
    .get(`${ROOT}/key-result/company/${companyId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setKeyResults(data.keyResults));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setKeyResultError(err.response.data.message));
      }
    });
};

export const updateKeyResult = (keyResultDetails, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/key-result`, keyResultDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setKeyResults(data.keyResults));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setKeyResultError(err.response.data.message));
      }
    });
};

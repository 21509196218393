import { Link, Stack, Typography } from '@mui/material';

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
    <Typography variant="caption">&copy; All rights reserved</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <Link href="https://co-operate.io" target="_blank" variant="caption">
        <Typography color="primary" type="p">
          About us
        </Typography>
      </Link>
      <Link href="https://info.co-operate.io/privacy-policy" target="_blank" variant="caption">
        <Typography color="primary" type="p">
          Privacy
        </Typography>
      </Link>
      <Link href="https://info.co-operate.io/terms-and-conditions" target="_blank" variant="caption">
        <Typography color="primary" type="p">
          Terms
        </Typography>
      </Link>
    </Stack>
  </Stack>
);

export default Footer;

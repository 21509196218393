import { useEffect, useState, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { openDrawer } from 'store/reducers/menu';
import Palette from '../../themes/palette';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { onChangeMode, onChangePresetColor, container, miniDrawer, presetColor } = useConfig();

  const company = user?.company;
  let mode = 'dark';

  if (user?.role === 'Client') {
    mode = company ? company.themeMode : 'dark';
  }

  const accent = company ? company.accentColor : '#46B3EC';

  // set the palete based on company's theme defaults
  const theme = useMemo(() => Palette(mode, accent), [mode, accent]);

  useEffect(() => {
    if (company && company.accentColor) {
      onChangePresetColor(company.accentColor);
    }
  }, [company]);

  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      {!location.pathname.endsWith('/timer') && (
        <>
          <Header open={open} handleDrawerToggle={handleDrawerToggle} />

          <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        </>
      )}

      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        {container && (
          <Container
            maxWidth="xl"
            sx={{ px: { xs: 0, sm: 2 }, position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}
          >
            {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
            <Outlet />
            <Footer />
          </Container>
        )}

        {!container && (
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
            <Outlet />
            <Footer />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MainLayout;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  session: null,
  sessions: null
};

const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessions(state, action) {
      state.sessions = action.payload;
    },
    setSession(state, action) {
      state.session = action.payload;
    },
    setSessionError(state, action) {
      state.error = action.payload;
    }
  }
});

export default session.reducer;

export const { setSessions, setSession, setSessionError } = session.actions;

export const closeSession = (sessionDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/session`, sessionDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setSession(data.session));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setSessionError(err.response.data.message));
      }
    });
};

export const getOrCreateOpenSession = (taskId, userId) => async (dispatch) => {
  axios
    .get(`${ROOT}/session/task/${taskId}/user/${userId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setSession(data.session));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setSessionError(err.response.data.message));
      }
    });
};

export const updateSession = (sessionId, seconds) => async (dispatch) => {
  axios
    .put(`${ROOT}/session`, { sessionId, seconds })
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setSession(data.session));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setSessionError(err.response.data.message));
      }
    });
};

export const getSessionsByTask = (taskId) => async (dispatch) => {
  axios
    .get(`${ROOT}/sessions/task/${taskId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setSessions(data.sessions));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setSessionError(err.response.data.message));
      }
    });
};

export const getSessionsByProject = (projectId) => async (dispatch) => {
  axios
    .get(`${ROOT}/sessions/project/${projectId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setSessions(data.sessions));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setSessionError(err.response.data.message));
      }
    });
};

export const getUserSessionsByProject = (projectId, userId) => async (dispatch) => {
  axios
    .get(`${ROOT}/sessions/project/${projectId}/user/${userId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setSessions(data.sessions));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setSessionError(err.response.data.message));
      }
    });
};

export const getSessionsByUser = (userId) => async (dispatch) => {
  axios
    .get(`${ROOT}/sessions/user/${userId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setSessions(data.sessions));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setSessionError(err.response.data.message));
      }
    });
};

// third-party
import { FormattedMessage } from 'react-intl';
import { GiArcheryTarget } from 'react-icons/gi';
import { GoMilestone } from 'react-icons/go';
import { BsFillKeyFill } from 'react-icons/bs';
import { AiFillProject } from 'react-icons/ai';
import { MdDashboard, MdSettings } from 'react-icons/md';
import { RiBuildingFill } from 'react-icons/ri';

import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  Objectives: GiArcheryTarget,
  Milestones: GoMilestone,
  KeyResults: BsFillKeyFill,
  Tasks: AiFillProject,
  Dashboard: MdDashboard,
  Account: MdSettings,
  Companies: RiBuildingFill,
  Projects: AiFillProject
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const main = {
  id: 'main',
  title: <FormattedMessage id="Main" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.Dashboard
    },
    {
      id: 'tasks',
      title: <FormattedMessage id="Tasks" />,
      type: 'item',
      url: '/tasks',
      icon: icons.Tasks
    }
    // {
    //   id: 'director-companies',
    //   title: <FormattedMessage id="Companies" />,
    //   type: 'item',
    //   url: '/companies',
    //   icon: icons.Companies
    // },
    // {
    //   id: 'director-projects',
    //   title: <FormattedMessage id="Projects" />,
    //   type: 'item',
    //   url: '/projects',
    //   icon: icons.Projects
    // }
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="Profile" />,
    //   type: 'item',
    //   url: '/account/profile',
    //   icon: icons.Account
    // }
  ]
};

export default main;

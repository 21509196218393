import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  user: null
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    logoutUser: (state) => {},
    setUserError(state, action) {
      state.error = action.payload;
    }
  }
});

export default user.reducer;

export const { setUser, setUsers, logoutUser, setUserError } = user.actions;

export const getUserByEmail = (email, callback) => async (dispatch) => {
  console.log(email);
  try {
    axios
      .get(`${ROOT}/user/email/${email}`)

      .then((response) => {
        console.log(response);
        if (callback) {
          callback();
        }
        return response.data;
      })
      .then((data) => {
        if (data.user) {
          dispatch(setUser(data.user));
        } else {
          dispatch(setUserError('We could not find a user with this email address.'));
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setUserError(err.response.data.message));
        }
      });
  } catch (err) {
    console.log(err);
  }
};

export const getUser = (id) => async (dispatch) => {
  axios
    .get(`${ROOT}/user/${id}`)

    .then((response) => {
      return response.data;
    })
    .then((data) => {
      if (data.user) {
        dispatch(setUser(data.user));
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setUserError(err.response.data.message));
      }
    });
};

export const getUsers = (type) => async (dispatch) => {
  axios
    .get(`${ROOT}/users/${type}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      if (data.users) {
        dispatch(setUsers(data.users));
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setUserError(err.response.data.message));
      }
    });
};

export const adminGetUsers = () => async (dispatch) => {
  axios
    .get(`${ROOT}/admin/users`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      if (data.users) {
        dispatch(setUsers(data.users));
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setUserError(err.response.data.message));
      }
    });
};

export const createUser = (data, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/user`, data)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      if (data.user) {
        dispatch(setUser(data.user));

        if (callback) {
          callback();
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setUserError(err.response.data.message));
      }
    });
};

export const updateUser = (data, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/user`, data)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      if (data.user) {
        dispatch(setUser(data.user));

        if (callback) {
          callback();
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setUserError(err.response.data.message));
      }
    });
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  task: null
};

const task = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTasks(state, action) {
      state.tasks = action.payload;
    },
    setParticles(state, action) {
      state.particles = action.payload;
    },
    setTask(state, action) {
      state.task = action.payload;
    },
    setTaskError(state, action) {
      state.error = action.payload;
    }
  }
});

export default task.reducer;

export const { setTasks, setTask, setParticles, setTaskError } = task.actions;

export const createTask = (taskDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/task`, taskDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setTask(data.task));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setTaskError(err.response.data.message));
      }
    });
};

export const getTask = (taskId) => async (dispatch) => {
  axios
    .get(`${ROOT}/task/${taskId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setTask(data.task));
      dispatch(setParticles(data.particles));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setTaskError(err.response.data.message));
      }
    });
};

export const getTasksByProject = (projectId) => async (dispatch) => {
  axios
    .get(`${ROOT}/task/project/${projectId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setTasks(data.tasks));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setTaskError(err.response.data.message));
      }
    });
};

export const getSpecialistTasksByProject = (projectId, specialistId) => async (dispatch) => {
  axios
    .get(`${ROOT}/task/project/${projectId}/specialist/${specialistId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setTasks(data.tasks));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setTaskError(err.response.data.message));
      }
    });
};

export const getTasksByCompany = (companyId) => async (dispatch) => {
  axios
    .get(`${ROOT}/task/company/${companyId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setTasks(data.tasks));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setTaskError(err.response.data.message));
      }
    });
};

export const getTasksByUser = (userId) => async (dispatch) => {
  axios
    .get(`${ROOT}/task/user/${userId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setTasks(data.tasks));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setTaskError(err.response.data.message));
      }
    });
};

export const updateTask = (taskDetails, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/task`, taskDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setTask(data.task));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setTaskError(err.response.data.message));
      }
    });
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  milestone: null
};

const milestone = createSlice({
  name: 'milestone',
  initialState,
  reducers: {
    setMilestone(state, action) {
      state.milestone = action.payload;
    },
    setMilestones(state, action) {
      state.milestones = action.payload;
    },
    setMilestoneError(state, action) {
      state.error = action.payload;
    }
  }
});

export default milestone.reducer;

export const { setMilestones, setMilestone, setMilestoneError } = milestone.actions;

export const createMilestone = (milestoneDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/milestone`, milestoneDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setMilestones(data.milestones));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setMilestoneError(err.response.data.message));
      }
    });
};

export const getMilestonesByCompany = (companyId) => async (dispatch) => {
  axios
    .get(`${ROOT}/milestone/company/${companyId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setMilestones(data.milestones));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setMilestoneError(err.response.data.message));
      }
    });
};

export const updateMilestone = (milestoneDetails, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/milestone`, milestoneDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setMilestone(data.milestone));

      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setMilestoneError(err.response.data.message));
      }
    });
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  company: null
};

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setAtomsInvested(state, action) {
      state.atomsInvested = action.payload;
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    setCompanies(state, action) {
      state.companies = action.payload;
    },
    setCompanyError(state, action) {
      state.error = action.payload;
    }
  }
});

export default company.reducer;

export const { setCompany, setCompanies, setCompanyError, setAtomsInvested } = company.actions;

export const getCompanyByUser = (userId) => async (dispatch) => {
  axios
    .get(`${ROOT}/company/user/${userId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setCompany(data.company));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCompanyError(err.response.data?.message));
      }
    });
};

export const getCompaniesByDirector = (userId) => async (dispatch) => {
  axios
    .get(`${ROOT}/companies/director/${userId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setCompanies(data.companies));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCompanyError(err.response.data?.message));
      }
    });
};

export const getCompany = (companyId) => async (dispatch) => {
  axios
    .get(`${ROOT}/company/${companyId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setCompany(data.company));
      dispatch(setAtomsInvested(data.atomsInvested));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCompanyError(err.response.data?.message));
      }
    });
};

export const createCompany = (data, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/company`, data)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setCompany(data.company));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCompanyError(err.response.data?.message));
      }
    });
};

export const updateCompany = (companyDetails, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/company`, companyDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setCompany(data.company));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCompanyError(err.response.data?.message));
      }
    });
};

export const adminGetCompanies = () => async (dispatch) => {
  axios
    .get(`${ROOT}/companies/admin`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setCompanies(data.companies));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCompanyError(err.response.data?.message));
      }
    });
};

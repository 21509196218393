import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  creators: null
};

const creator = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    setCreators(state, action) {
      state.creators = action.payload;
    },
    setCreatorError(state, action) {
      state.error = action.payload;
    }
  }
});

export default creator.reducer;

export const { setCreators, setCreatorError } = creator.actions;

export const getCreators = (config) => async (dispatch) => {
  axios
    .post(`${ROOT}/creators`, config)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setCreators(data.creators));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCreatorError(err.response.data.message));
      }
    });
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  project: null
};

const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setParticles(state, action) {
      state.particles = action.payload;
    },
    setAvailableDollars(state, action) {
      state.availableDollars = action.payload;
    },
    setProjects(state, action) {
      state.projects = action.payload;
    },
    setSpecialists(state, action) {
      state.specialists = action.payload;
    },
    setProject(state, action) {
      state.project = action.payload;
    },
    setProjectError(state, action) {
      state.error = action.payload;
    }
  }
});

export default project.reducer;

export const { setProjects, setProject, setParticles, setSpecialists, setAvailableDollars, setProjectError } = project.actions;

export const createProject = (projectDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/project`, projectDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setProject(data.project));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data?.message));
      }
    });
};

export const getProject = (projectId) => async (dispatch) => {
  axios
    .get(`${ROOT}/project/${projectId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setProject(data.project));
      dispatch(setParticles(data.particles));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data?.message));
      }
    });
};

export const getProjectAvailableDollars = (projectId) => async (dispatch) => {
  axios
    .get(`${ROOT}/project/${projectId}/available`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setAvailableDollars(data.dollars));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data?.message));
      }
    });
};

export const getProjectSpecialists = (projectId) => async (dispatch) => {
  axios
    .get(`${ROOT}/project/${projectId}/specialists`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setSpecialists(data.specialists));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data?.message));
      }
    });
};

export const getProjectsByCompany = (companyId) => async (dispatch) => {
  axios
    .get(`${ROOT}/project/company/${companyId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setProjects(data.projects));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data?.message));
      }
    });
};

export const getProjectsBySpecialist = (specialistId) => async (dispatch) => {
  axios
    .get(`${ROOT}/projects/specialist/${specialistId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setProjects(data.projects));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data?.message));
      }
    });
};

export const getProjectsByDirector = (directorId) => async (dispatch) => {
  axios
    .get(`${ROOT}/projects/director/${directorId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setProjects(data.projects));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data.message));
      }
    });
};

export const adminGetProjects = () => async (dispatch) => {
  axios
    .get(`${ROOT}/projects/admin`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setProjects(data.projects));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data?.message));
      }
    });
};

export const updateProject = (projectDetails) => async (dispatch) => {
  axios
    .put(`${ROOT}/project`, projectDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setProject(data.project));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setProjectError(err.response.data?.message));
      }
    });
};

import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

import useAuth from 'hooks/useAuth';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { user } = useAuth();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const navGroups = menuItem.items.map((item) => {
    // Hide Company and Plan sections if not client
    if (user && item.id === 'company' && user.role !== 'Client') {
      return;
    }

    if (user && item.id === 'plan' && user.role !== 'Client') {
      return;
    }

    if (user && item.id === 'admin' && user.role !== 'Admin') {
      return;
    }

    if (user && item.id === 'director' && user.role !== 'Director') {
      return;
    }

    switch (item.type) {
      case 'group':
        return <NavGroup type="group" key={item.id} item={item} />;
      case 'single':
        return <NavGroup type="single" key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;

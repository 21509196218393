import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Onboarding = Loadable(lazy(() => import('pages/company/onboarding')));
const Vision = Loadable(lazy(() => import('pages/company/vision')));
const Mission = Loadable(lazy(() => import('pages/company/mission')));
const Branding = Loadable(lazy(() => import('pages/company/branding')));
const OrgChart = Loadable(lazy(() => import('pages/company/org-chart')));
const CompanySettings = Loadable(lazy(() => import('pages/company/settings')));
const Objectives = Loadable(lazy(() => import('pages/plan/objectives')));
const Projects = Loadable(lazy(() => import('pages/plan/projects')));
const Milestones = Loadable(lazy(() => import('pages/plan/milestones')));
const KeyResults = Loadable(lazy(() => import('pages/plan/key-results')));
const Dashboard = Loadable(lazy(() => import('pages/account/dashboard')));
const Tasks = Loadable(lazy(() => import('pages/tasks/tasks')));
const Companies = Loadable(lazy(() => import('pages/companies/companies')));
const Project = Loadable(lazy(() => import('pages/project/project')));
const Task = Loadable(lazy(() => import('pages/task/task')));
const Company = Loadable(lazy(() => import('pages/company/company')));
const Timer = Loadable(lazy(() => import('pages/task/timer/timer')));

const CompaniesAdmin = Loadable(lazy(() => import('pages/admin/companies')));
const UsersAdmin = Loadable(lazy(() => import('pages/admin/users')));
const ProjectsAdmin = Loadable(lazy(() => import('pages/admin/projects')));

const User = Loadable(lazy(() => import('pages/user/user')));
const Account = Loadable(lazy(() => import('pages/account/account')));
const Profile = Loadable(lazy(() => import('pages/account/tabs/profile')));
const EditProfile = Loadable(lazy(() => import('pages/account/tabs/edit-profile')));
const Password = Loadable(lazy(() => import('pages/account/tabs/password')));
const Settings = Loadable(lazy(() => import('pages/account/tabs/settings')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'tasks',
          element: <Tasks />
        },
        {
          path: 'projects',
          element: <Projects />
        },
        {
          path: 'companies',
          element: <Companies />
        },
        {
          path: 'account',
          element: <Account />,
          children: [
            {
              path: 'profile',
              element: <Profile />
            },
            {
              path: 'edit-profile',
              element: <EditProfile />
            },
            {
              path: 'settings',
              element: <Settings />
            },

            {
              path: 'password',
              element: <Password />
            }
          ]
        },
        {
          path: '/task/:id/timer',
          element: <Timer />
        },
        {
          path: '/task/:id',
          element: <Task />
        },
        {
          path: '/project/:id',
          element: <Project />
        },
        {
          path: '/user/:id',
          element: <User />
        },
        {
          path: '/company/:id',
          element: <Company />
        }
      ]
    },
    {
      path: '/company',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'onboarding',
          element: <Onboarding />
        },
        {
          path: 'settings',
          element: <CompanySettings />
        },
        {
          path: 'vision',
          element: <Vision />
        },
        {
          path: 'mission',
          element: <Mission />
        },
        {
          path: 'branding',
          element: <Branding />
        },
        {
          path: 'org-chart',
          element: <OrgChart />
        }
      ]
    },
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'users',
          element: <UsersAdmin />
        },
        {
          path: 'companies',
          element: <CompaniesAdmin />
        },
        {
          path: 'projects',
          element: <ProjectsAdmin />
        }
      ]
    },
    {
      path: '/plan',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'objectives',
          element: <Objectives />
        },
        {
          path: 'milestones',
          element: <Milestones />
        },
        {
          path: 'key-results',
          element: <KeyResults />
        },
        {
          path: 'projects',
          element: <Projects />
        }
      ]
    }
  ]
};

export default MainRoutes;

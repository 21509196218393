import other from './other';
import company from './company';
import plan from './plan';
import main from './main';
import admin from './admin';
import director from './director';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [main, admin, plan, company, director]
};

export default menuItems;

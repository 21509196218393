import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

import useConfig from 'hooks/useConfig';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import { useSelector } from 'store';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'axios';
import { Magic } from 'magic-sdk';

const ROOT = process.env.REACT_APP_SERVER_URL;

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  // const { onChangeMode } = useConfig();
  const { company } = useSelector((state) => state.company);

  // useEffect(() => {
  //   if (company && company.themeMode) {
  //     onChangeMode(company.themeMode);
  //   }
  // }, [company]);

  useEffect(() => {
    const init = async () => {
      try {
        const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);

        const { email, publicAddress } = await magic.user.getMetadata();

        if (email) {
          const didToken = await magic.auth.loginWithMagicLink({
            email: email
          });

          const response = await axios.post(
            `${ROOT}/login`,
            { email },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + didToken
              }
            }
          );

          const { token, user } = response.data;
          setSession(token);

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async ({ email, password }) => {
    try {
      const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);
      const didToken = await magic.auth.loginWithMagicLink({
        email: email
      });
      const response = await axios.post(
        `${ROOT}/login`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + didToken
          }
        }
      );

      const { user } = response.data;

      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user
        }
      });
    } catch (error) {
      throw new Error(error.response.data.error || error.message);
    }
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post(`${ROOT}/user`, {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);
    magic.user.logout();
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;

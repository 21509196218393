// third-party
import { FormattedMessage } from 'react-intl';
import { GiArcheryTarget } from 'react-icons/gi';
import { GoMilestone } from 'react-icons/go';
import { BsFillKeyFill } from 'react-icons/bs';
import { AiFillProject } from 'react-icons/ai';
import { HiUsers } from 'react-icons/hi';
import { RiBuildingFill } from 'react-icons/ri';

// icons
const icons = {
  Users: HiUsers,
  Companies: RiBuildingFill,
  Projects: AiFillProject
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const admin = {
  id: 'admin',
  title: <FormattedMessage id="Admin" />,
  type: 'group',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      url: '/admin/users',
      icon: icons.Users
    },
    {
      id: 'companies',
      title: <FormattedMessage id="Companies" />,
      type: 'item',
      url: '/admin/companies',
      icon: icons.Companies
    },
    {
      id: 'projects',
      title: <FormattedMessage id="Projects" />,
      type: 'item',
      url: '/admin/projects',
      icon: icons.Projects
    }
  ]
};

export default admin;

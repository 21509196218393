import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  logs: null
};

const log = createSlice({
  name: 'log',
  initialState,
  reducers: {
    setLogs(state, action) {
      state.logs = action.payload;
    },
    setLogError(state, action) {
      state.error = action.payload;
    }
  }
});

export default log.reducer;

export const { setLogs, setLogError } = log.actions;

export const createLog = (logDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/log`, logDetails)
    .then((response) => {
      return response.data;
    })
    .then(() => {
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setLogError(err.response.data.message));
      }
    });
};

export const getLogs = (config) => async (dispatch) => {
  axios
    .post(`${ROOT}/logs`, config)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setLogs(data.logs));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setLogError(err.response.data.message));
      }
    });
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  asset: null,
  assets: null
};

const asset = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setAssets(state, action) {
      state.assets = action.payload;
    },
    setAsset(state, action) {
      state.asset = action.payload;
    },
    setAssetError(state, action) {
      state.error = action.payload;
    }
  }
});

export default asset.reducer;

export const { setAssets, setAsset, setAssetError } = asset.actions;

export const createAsset = (assetDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/asset`, assetDetails)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setAssets(data.assets));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAssetError(err.response.data.message));
      }
    });
};

export const getAsset = (assetId) => async (dispatch) => {
  axios
    .get(`${ROOT}/asset/${assetId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setAsset(data.asset));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAssetError(err.response.data.message));
      }
    });
};

export const getAssets = (config) => async (dispatch) => {
  axios
    .post(`${ROOT}/assets`, config)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setAssets(data.assets));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAssetError(err.response.data.message));
      }
    });
};

export const updateAssets = (data, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/assets`, data)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setAssets(data.assets));

      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAssetError(err.response.data.message));
      }
    });
};

export const updateAsset = (data, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/asset`, data)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setAsset(data.asset));

      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAssetError(err.response.data.message));
      }
    });
};

import { FormattedMessage } from 'react-intl';
import { FaPlay, FaBinoculars, FaPaintBrush, FaMountain } from 'react-icons/fa';
import { CgDetailsLess } from 'react-icons/cg';
import { RiOrganizationChart } from 'react-icons/ri';

import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  Onboarding: FaPlay,
  Details: CgDetailsLess,
  Vision: FaBinoculars,
  Mission: FaMountain,
  OrgChart: RiOrganizationChart,
  Branding: FaPaintBrush
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const company = {
  id: 'company',
  title: <FormattedMessage id="Company" />,
  type: 'group',
  children: [
    {
      id: 'onboarding',
      title: <FormattedMessage id="Onboarding" />,
      type: 'item',
      url: '/company/onboarding',
      icon: icons.Onboarding,
      chip: {
        label: 'New',
        color: 'secondary',
        size: 'small'
      }
    },
    // {
    //   id: 'settings',
    //   title: <FormattedMessage id="Settings" />,
    //   type: 'item',
    //   url: '/company/settings',
    //   icon: icons.Details
    // },
    {
      id: 'vision',
      title: <FormattedMessage id="Vision" />,
      type: 'item',
      url: '/company/vision',
      icon: icons.Vision
    },
    {
      id: 'mission',
      title: <FormattedMessage id="Mission" />,
      type: 'item',
      url: '/company/mission',
      icon: icons.Mission
    },
    {
      id: 'org-chart',
      title: <FormattedMessage id="Org Chart" />,
      type: 'item',
      url: '/company/org-chart',
      icon: icons.OrgChart
    },
    {
      id: 'branding',
      title: <FormattedMessage id="Branding" />,
      type: 'item',
      url: '/company/branding',
      icon: icons.Branding
    }
  ]
};

export default company;

// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import cartReducer from './cart';
import user from './user';
import company from './company';
import objective from './objective';
import keyResult from './key-result';
import milestone from './milestone';
import project from './project';
import task from './task';
import comment from './comment';
import asset from './asset';
import session from './session';
import log from './log';
import creator from './creator';

const combinedReducer = combineReducers({
  chat,
  calendar,
  menu,
  snackbar,
  user,
  company,
  objective,
  keyResult,
  milestone,
  project,
  task,
  comment,
  asset,
  session,
  log,
  creator,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'mantis-ts-'
    },
    cartReducer
  ),
  product: productReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'user/logoutUser') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const ROOT = process.env.REACT_APP_SERVER_URL;

const initialState = {
  comments: null
};

const comment = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setComments(state, action) {
      state.comments = action.payload;
    },
    setComment(state, action) {
      state.comment = action.payload;
    },
    setCommentError(state, action) {
      state.error = action.payload;
    }
  }
});

export default comment.reducer;

export const { setComments, setComment, setCommentError } = comment.actions;

export const createComment = (commentDetails, callback) => async (dispatch) => {
  axios
    .post(`${ROOT}/comment`, commentDetails)
    .then((response) => {
      return response.data;
    })
    .then(() => {
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCommentError(err.response.data?.message));
      }
    });
};

export const updateComment = (commentDetails, callback) => async (dispatch) => {
  axios
    .put(`${ROOT}/comment`, commentDetails)
    .then((response) => {
      return response.data;
    })
    .then(() => {
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCommentError(err.response.data?.message));
      }
    });
};

// config: task, project, author, body, isInternal
export const getComments = (config) => async (dispatch) => {
  axios
    .post(`${ROOT}/comments`, config)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setComments(data.comments));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCommentError(err.response.data?.message));
      }
    });
};

export const getSpecialistProjectComments = (projectId, userId) => async (dispatch) => {
  axios
    .get(`${ROOT}/comments/project/${projectId}/user/${userId}`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      dispatch(setComments(data.comments));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setCommentError(err.response.data?.message));
      }
    });
};

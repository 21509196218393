// third-party
import { FormattedMessage } from 'react-intl';
import { GiArcheryTarget } from 'react-icons/gi';
import { GoMilestone } from 'react-icons/go';
import { BsFillKeyFill } from 'react-icons/bs';
import { AiFillProject } from 'react-icons/ai';

import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  Objectives: GiArcheryTarget,
  Milestones: GoMilestone,
  KeyResults: BsFillKeyFill,
  Projects: AiFillProject
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const plan = {
  id: 'plan',
  title: <FormattedMessage id="Plan" />,
  type: 'group',
  children: [
    {
      id: 'projects',
      title: <FormattedMessage id="Projects" />,
      type: 'item',
      url: '/plan/projects',
      icon: icons.Projects
      // disabled: true
    },
    {
      id: 'objectives',
      title: <FormattedMessage id="Objectives" />,
      type: 'item',
      url: '/plan/objectives',
      icon: icons.Objectives
    },
    {
      id: 'key-results',
      title: <FormattedMessage id="Key Results" />,
      type: 'item',
      url: '/plan/key-results',
      icon: icons.KeyResults
      // disabled: true
    },
    {
      id: 'milestones',
      title: <FormattedMessage id="Milestones" />,
      type: 'item',
      url: '/plan/milestones',
      icon: icons.Milestones
      // disabled: true
    }
  ]
};

export default plan;
